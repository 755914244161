import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/fontawesome-free-regular';
import { faSquareFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faSchool, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Enauczanie from '../../assets/enauczanie.png';
import LogoPG from '../../assets/Logo_PG.png';
import LogoWILIS from '../../assets/Logo_WILIS.png';
import LogoKG from '../../assets/Logo_KG.png';
import './footer.css';


const Footer = () => {
  return (
    <div className="gis__footer blurrable">
      <span className="anchor-contact" id="gis_contact" />
      <div className="gis__footer-container">
        <div className="gis__footer-contactinfo">
          <h1>Kontakt</h1>
          <h3><span><FontAwesomeIcon icon={faLocationDot}/></span>Adres</h3>
          <p>Gabriela Narutowicza 11/12,<br />80-233 Gdańsk</p>
          <h3><span><FontAwesomeIcon icon={faSchool} /></span>Budynek</h3>
          <p>Politechnika Gdańska</p>
          <p>Wydział Inżynierii Lądowej i&nbsp;Środowiska</p>
          <p>Katedra Geodezji</p>
          <p>Budynek nr 20 - "Hydro"</p>
          <h3><span><FontAwesomeIcon icon={faEnvelope}/></span>E-mail</h3>
          <a href="mailto:adam.inglot@pg.edu.pl" target="_blank" rel="noreferrer"><p className="p-mail">adam.inglot@pg.edu.pl</p></a>
          <h3><span><FontAwesomeIcon icon={faPhone} /></span>Telefony</h3>
          <a href="tel:583471919" target="_blank" rel="noreferrer"><p className="p-tel">58 347 19 19</p></a>
        </div>
        <div className="gis__footer-links">
          <h1>Nawigacja</h1>
          <a href="#home"><p>Home</p></a>
          <a href="#gis_about"><p>O kierunku</p></a>
          <a href="#gis_location"><p>Lokalizacja</p></a>
          <a href="#gis_lecturer"><p>Prowadzący</p></a>
          <a href="#gis_gallery"><p>Galeria</p></a>
          <a href="#gis_contact"><p>Kontakt</p></a>
          <h1>Linki</h1>
          <a href="https://moja.pg.edu.pl/" target="_blank" rel="noreferrer"><p>Moja PG</p></a>
          <a href="https://pg.edu.pl/" target="_blank" rel="noreferrer"><p>Strona Główna PG</p></a>
          <a href="https://enauczanie.pg.edu.pl/moodle/" target="_blank" rel="noreferrer"><p>eNauczanie</p></a>
          <a href="https://mostwiedzy.pl/pl/" target="_blank" rel="noreferrer"><p>Most Wiedzy</p></a>

        </div>
        <div className="gis__footer-pg">
          <a href="https://pg.edu.pl/" target="_blank" rel="noreferrer"><img src={LogoPG} alt="Logo PG" /></a>
          <a href="https://wilis.pg.edu.pl/" target="_blank" rel="noreferrer"><img src={LogoWILIS} alt="Logo WILiŚ" /></a>
          <a id="logo-last" href="https://wilis.pg.edu.pl/kg" target="_blank" rel="noreferrer"><img src={LogoKG} alt="Logo KG" /></a>
        </div>
      </div>
      <div className="gis__footer-copyright">
        <div className="gis__footer-copyright-enauczanie">
          <a href="https://enauczanie.pg.edu.pl/moodle/" target="_blank" rel="noreferrer"><button type="button"><img src={Enauczanie} alt="eNauczanie"/></button></a>
        </div>
        <div className="gis__footer-copyright-design">
          <h2>Copyright&nbsp;<FontAwesomeIcon icon={faCopyright} />&nbsp;2023 Jakub Jabłoński. All rights reserved.</h2>
        </div>
        <div className="gis__footer-copyright-sm">
          <a className="gis__footer-copyright-sm_fb" href="https://www.facebook.com/KatedraGeodezji/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSquareFacebook} /></a>
          <a className="gis__footer-copyright-sm_ig" href="https://www.instagram.com/katedra_geodezji_pg/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
          <a className="gis__footer-copyright-sm_yt" href="https://www.youtube.com/@katedrageodezji_pg" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer