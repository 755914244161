import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import './location.css';

const Location = () => {
  return (
    <div className="gis__location blurrable">
        <span className="anchor-location" id="gis_location" />
        <div className="gis__location-heading">
            <div className="gis__location-heading-pin"><p><FontAwesomeIcon icon={faMapLocationDot}></FontAwesomeIcon></p></div>
            <h1 className="gis__location-heading-text gradient__text">Lokalizacja</h1>
        </div>
        <div className="gis__location-container">
            <div className="gis__location-container_map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d708.8968357873154!2d18.61614847821425!3d54.371952160195384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd749a7b639c41%3A0xbcd7e6cf18c13e62!2zNTTCsDIyJzE5LjYiTiAxOMKwMzYnNTkuMiJF!5e1!3m2!1spl!2spl!4v1676543742591!5m2!1spl!2spl" title="googlemaps"></iframe>
            </div>
            <div className="gis__location-container_description">
                <h1>Politechnika Gdańska</h1>
                <h2>Wydział Inżynierii Lądowej i&nbsp;Środowiska</h2>
                <h3>Katedra Geodezji</h3>
            </div>
        </div>
    </div>
  )
}

export default Location