import React, { useLayoutEffect, useState } from 'react';
import './lecturerslider.css';
let delayed=0;

const Lecturerslider = (props) => {
    const {children, show, infiniteLoop} = props

    const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0)
    const [length, setLength] = useState(children.length)
    
    const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show)
    const [transitionEnabled, setTransitionEnabled] = useState(true)

    const [touchPosition, setTouchPosition] = useState(null)

    const handleTransitionEnd = () => {
        if (isRepeating) {
            if (currentIndex <= 0) {
                setTransitionEnabled(false)
                setCurrentIndex(length)
            } else if (currentIndex >= length + show) {
                setTransitionEnabled(false)
                setCurrentIndex(show)
            }
        }
    }

    // Set the length to match current children from props
    // Zmieniono na useLayoutEffect, aby zagwarantować synchronizację z cyklem renderowania
    useLayoutEffect(() => {
        setLength(children.length)
        setIsRepeating(infiniteLoop && children.length > show)
    }, [children, infiniteLoop, show])

    // Zmieniono na useLayoutEffect, aby zagwarantować synchronizację z cyklem renderowania
    /*useLayoutEffect(() => {
        if (isRepeating) {
            if (currentIndex === show || currentIndex === length) {
                setTransitionEnabled(true)
            }
        }
    }, [currentIndex, isRepeating, show, length])*/

     // Zmieniono na useLayoutEffect, aby zagwarantować synchronizację z cyklem renderowania
     useLayoutEffect(() => {
        if (!transitionEnabled) {
            // Ponowne włączenie animacji po zakończeniu przeskoku
            setTimeout(() => setTransitionEnabled(true), 50);
        }
    }, [transitionEnabled]);

    /*useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevState => prevState +1);
        }, 10000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });*/

    /*const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )*/

    // Zmieniono setTimeout na requestAnimationFrame dla płynniejszych animacji
    /*const delay = ms => new Promise(resolve => requestAnimationFrame(resolve));*/

    /*const next = async event => {
        if (delayed === 0 && (isRepeating || currentIndex < (length - show))) {
                setCurrentIndex(prevState => prevState + 1);
                delayed = 1;
                await delay(350);
                delayed = 0;
        }

    }

    const prev = async event => {
        if (delayed === 0 && (isRepeating || currentIndex > 0)) {
                setCurrentIndex(prevState => prevState - 1);
                delayed = 1;
                await delay(350);
                delayed = 0;
        }
    }*/

    // Zmieniono logikę na użycie useTransition
    /*const next = async event => {
        if (delayed === 0 && (isRepeating || currentIndex < (length - show))) {
            startTransition(() => {
                setCurrentIndex(prevState => prevState + 1);
            });
            delayed = 1;
            await delay(350);
            delayed = 0;
        }
    };

    const prev = async event => {
        if (delayed === 0 && (isRepeating || currentIndex > 0)) {
            startTransition(() => {
                setCurrentIndex(prevState => prevState - 1);
            });
            delayed = 1;
            await delay(350);
            delayed = 0;
        }
    };*/

    const next = async () => {
        if (delayed === 0 && (isRepeating || currentIndex < (length - show))) {
            // Aktualizujemy stan bez startTransition, aby nie było problemów z asynchronicznością
            setCurrentIndex(prevState => prevState + 1);
            delayed = 1; // Blokujemy kolejne kliknięcia
            await delay(350); // Czekamy na opóźnienie
            delayed = 0; // Odblokowujemy kliknięcia po opóźnieniu
        }
    };
    
    const prev = async () => {
        if (delayed === 0 && (isRepeating || currentIndex > 0)) {
            // Aktualizujemy stan bez startTransition
            setCurrentIndex(prevState => prevState - 1);
            delayed = 1; // Blokujemy kolejne kliknięcia
            await delay(350); // Czekamy na opóźnienie
            delayed = 0; // Odblokowujemy kliknięcia po opóźnieniu
        }
    };
    
    // Funkcja delay wykorzystująca setTimeout dla pełnej kontroli nad opóźnieniem
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    const renderExtraPrev = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[length - 1 - index])
        }
        output.reverse()
        return output
    }

    const renderExtraNext = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[index])
        }
        return output
    }

    return (
        <div className="carousel-container blurrable">
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {
                    (isRepeating || currentIndex > 0) &&
                    <button onClick={prev} className="left-arrow">
                        <p>&#10096;</p>
                    </button>
                }
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{
                            transform: `translateX(-${currentIndex * (100 / show)}%)`,
                            transition: !transitionEnabled ? 'none' : undefined,
                        }}
                        onTransitionEnd={() => handleTransitionEnd()}
                    >
                        {
                            (length > show && isRepeating) &&
                            renderExtraPrev()
                        }
                        {children}
                        {
                            (length > show && isRepeating) &&
                            renderExtraNext()
                        }
                    </div>
                </div>
                {/* You can alwas change the content of the button to other things */}
                {
                    (isRepeating || currentIndex < (length - show)) &&
                    <button onClick={next} className="right-arrow">
                        <p>&#10097;</p>
                    </button>
                }
                {/*<div className="indicators">
                {
                    React.Children.map(children, (child, index) => {
                        return (
                            (isRepeating || currentIndex) &&
                            <div onClick={() => { setCurrentIndex(index+1);}} className={`${index === currentIndex-1 ? "active" : ""}`} />
                        );
                })}
            </div>*/}
            </div>
        </div>
    )
}

export default Lecturerslider