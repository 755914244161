import React from 'react';
import './testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const testimonialsData = [
    {
      review: "GIS na PG to świetne miejsce aby zacząć swoją przygodę z GISem. Jest też doskonałym sposobem na rozwój już istniejących umiejętności GISowych. To niesamowita dawka wiedzy praktycznej od profesjonalistów."
    },
    {
      review: "Studia GIS na PG to świetne wprowadzenie do świata GIS. Kompetentni prowadzący, doskonałe zaplecze, przemyślany program."
    },
    {
      review: "Zajęcia były ciekawe i obejmowały zróżnicowaną tematykę. Szeroka kadra dydaktyczna zapewniała wysoką jakość nauczania, a możliwość uczestniczenia w zajęciach online była dużym udogodnieniem. Polecam!"
    }
  ];

const Testimonials = () => {
  return (
    <div className="gis__testimonials">
        <div className="testimonials-header">
            <h1>Opinie naszych absolwentów</h1>
        </div>
        <div className="testimonials-container">
            {testimonialsData.map((testimonial, index) => (
                <div className="testimonial-card" key={index}>
                    <div className="stars">
                        <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} />
                    </div>
                    <p className="review">{testimonial.review}</p>
                    {/*<h3 className="name">{testimonial.name}</h3>*/}
                </div>
            ))}
        </div>
    </div>
  )
}

export default Testimonials